
// This file contains all the COPYRIGHT OVERRIDES FOR SAO styles
// This file is imported into the delivery-variant-sao.scss file

.fp-copyright {
  p {
    color: $color-secondary--50;
  }

  &__links {
    a {
      color: $color-secondary--30;
    }
  }
}