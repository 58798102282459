// Layout Variables
@import '../../basics/layout-variables';

// override any variable below


// Typography Variables
$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Poppins', sans-serif;

// Color Variables
$color-primary: #e5732e;
$color-secondary: #0C2940;
$color-text: #0C2940;
$color-secondary--50: #6C6767;
$color-secondary--30: #7D8693;

// Border Variables
$border-primary: 1px solid $color-primary;