
// This file contains all the SOCIAL MEDIA OVERRIDES FOR SAO styles
// This file is imported into the delivery-variant-sao.scss file

// Styles exclusive to the footer social media

.fp-footer {
  .fp-social-media {

    &__twitter,
    &__facebook {
      path {
        fill: $white;
      }
      &:hover {
        opacity: 0.8;
      }
    }

    &__instagram {
      path {
        stroke: $white;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}