

// This file contains all the SIDEBAR MENU OVERRIDES FOR SAO styles
// This file is imported into the delivery-variant-sao.scss file

.fp-sidebar-menu {
  &__header-logo {
    @include size(null, 30px);
  }

  &__main-nav, &__nav {
    .fp-sidebar-menu__nav--header {
      h4 {
        color: $color-text;
        text-transform: initial;
        font-size: $text-md;
        a {
          color: $color-text;
          &:visited {
            color: $color-text;
          }
        }
      }
      span {
        color: $color-text;
        text-transform: initial;
        font-size: $text-md;
        a {
          color: $color-text;
          &:visited {
            color: $color-text;
          }
        }
      }
    }

    .fp-sidebar-menu__nav--item {
      a {
        color: $color-secondary--30;
      }
      &:before {
        color: $color-secondary--30;
      }
    }
  }
}