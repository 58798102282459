
// This file contains all the FOOTER OVERRIDES FOR SAO styles
// This file is imported into the delivery-variant-sao.scss file

.fp-footer {
  .fp-container__extra-large {
    @media(max-width: $mobile) {
      @include size(100%, null);
    }
  }


  &__header {
    @include padding(0 0 $spacer * 4);
    border-bottom: $border-primary;
    border-bottom-width: 2px;
    figure {
      @include size(100%, auto);
    }
  }

  &__body {
    @include padding($spacer * 9 5%);
    @include margins($spacer 0 0);
    background: $color-secondary;
    border-bottom: 0;
    @media(max-width: $mobile) {
      @include padding($spacer * 8 4%);
    }
  }

  &__links {
    grid-template-columns: auto auto;
    grid-column-gap: $spacer * 10;
    @media(max-width: $mobile) {
      grid-template-columns: 1fr;
      margin-bottom: 0;
    }
    h4 {
      font-size: $text-md;
      color: $white;
      text-transform: uppercase;
      a {
        color: $white;
        &:visited {
          color: $white;
        }
      }
    }

    li {
      a {
        font-size: $text-sm;
        color: $white;
      }

      &:before {
        content: '•';
        @include margins(0 $spacer);
        color: $white;
      }

      &:first-of-type {
        &:before {
          content: '';
          @include margins(0);
        }
      }
    }
  }
}