
// This file contains all the NEWSLETTER FORM OVERRIDES FOR SAO styles
// This file is imported into the delivery-variant-sao.scss file

.fp-newsletter {
  h4 {
    font-size: $text-md;
  }

  &__checkbox-wrapper {
    p {
      a {
        color: $color-primary;
      }
    }

    input[type=checkbox] {
      &:checked ~ .fp-newsletter__checkbox {
        background: $color-primary;
        border: 1px solid $color-primary;
      }
    }
  }

  &__submit {
    font-weight: $font-semibold;
  }
}

// Styles exclusive to the FOOTER newsletter form

.fp-footer {
  .fp-newsletter {
    h4 {
      color: $white;
      margin-bottom: $spacer * 8;
    }

    &__input,
    &__checkbox {
      border-color: $white;
    }

    &__input {
      color: $white;
      &::placeholder {
        color: $white;
      }
    }

    &__checkbox-wrapper {
      p {
        color: $white;
      }
    }

    &__close {
      svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}