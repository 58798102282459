// This file contains all the HEADER OVERRIDES FOR SAO styles
// This file is imported into the delivery-variant-sao.scss file

.fp-main-header {
  &__links {
    a {
      font-weight: $font-medium;
      color: $color-text;
    }
  }

  &__weather-widget {
    &--top {
      aside {
        span {
          color: $color-text;
        }

        svg {
          path {
            stroke: $color-primary;
          }
        }
      }
    }

    &--bottom {
      span {
        font-weight: $font-semibold;
        color: $color-text;
      }
    }
  }
}

.fp-header-responsive {
  &__top {
    &__logo {
      @media (max-width: $mobile) {
        @include size(auto, 30px);
        a,
        img {
          @include size(100%, 100%);
        }
      }
    }
  }
}

.fp-header-responsive .fp-header-responsive__top {
  justify-content: initial;
}

.fp-header-responsive .fp-header-responsive__top__logo {
  margin: 0 auto;
  padding-right: 23px;
}
