// This file contains all the TOP NAVIGATION OVERRIDES FOR SAO styles
// This file is imported into the delivery-variant-sao.scss file

.fp-top-nav {
  &__list-item {
    a {
      font-weight: $font-regular;
      color: $color-text;
    }
  }
}