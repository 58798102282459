
// This file contains all the NAVIGATION OVERRIDES FOR SAO styles
// This file is imported into the delivery-variant-sao.scss file

.fp-main-nav {
  border-bottom-width: 2px;

  &__local,
  &__list-item {
    a {
      font-weight: $font-medium;
      text-transform: uppercase;
      color: $black;
    }
  }
}